import styled, { css } from 'styled-components'

import media from 'styles/media'

import iconPhone from 'assets/icons/phone.svg'
import iconEmail from 'assets/icons/envelope.svg'

export const Wrapper = styled.section`
  position: relative;
  border-top: 1px solid ${({ theme }) => theme.colors.gray80};
`

export const TradersList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 48px;
  grid-row-gap: 64px;
  margin: 0 auto;
  padding: 72px 0;
  width: 100%;
  max-width: 1200px;

  ${media.xl.max} {
    padding: 54px 0;
    grid-template-columns: repeat(2, 1fr);
  }

  ${media.lg.max} {
    grid-template-columns: repeat(1, 1fr);
    padding: 48px 0;
    gap: 48px;
  }
`

export const Trader = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.xl.max} {
    align-items: flex-start;
  }
`

export const TraderInner = styled.article`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const TraderTitle = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 26px;
  line-height: 34px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};

  ${media.lg.max} {
    font-size: 22px;
    line-height: 30px;
  }
`

export const TraderSubtitle = styled.h4`
  margin: 12px 0 0 0;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
`

export const TraderText = styled.p`
  margin: 4px 0 0 0;
  font-size: 18px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.white};
`

export const TraderContact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 24px 0 0 0;
`

export const traderContactStyles = css`
  position: relative;
  margin: 0 0 24px 0;
  padding: 0 0 0 32px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 300ms ease-in-out;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 24px;
    height: 24px;
    background-size: 22px;
    background-position: center;
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
  &:last-child {
    margin: 0;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.primary50};
  }
`

export const TraderPhone = styled.a`
  ${traderContactStyles}

  &:before {
    background-image: url(${iconPhone});
  }
`

export const TraderEmail = styled.a`
  ${traderContactStyles}

  &:before {
    background-image: url(${iconEmail});
  }
`

export const Company = styled.div`
  position: relative;
  border-top: 1px solid ${({ theme }) => theme.colors.gray80};
  padding: 54px 0;
  width: 100%;
  ${media.lg.max} {
    padding: 48px 0;
  }
`

export const CompanyInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;

  ${media.lg.max} {
    max-width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`

export const CompanyCol = styled.div`
  margin: 0 0 0 72px;

  ${media.lg.min} {
    &:first-child {
      margin: 0;
    }
  }

  ${media.lg.max} {
    margin: 0 0 24px 0;
    &:last-child {
      margin: 0;
    }
  }
`

export const CompanyText = styled.p`
  margin: 0 0 4px 0;
  font-size: 16px;
  line-height: 24px;
  &:last-child {
    margin: 0;
  }
`
