import * as React from 'react'
import { PageProps, graphql } from 'gatsby'
import styled from 'styled-components'

import LayoutBasic from 'layouts/LayoutBasic'
import Seo from 'components/atoms/Seo'
import ContactForm from 'components/modules/ContactForm'
import Container from 'components/atoms/Container'
import { LazyImage } from 'components/atoms/Image'
import Traders from 'components/organisms/Traders'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

const StyledFormBg = styled(LazyImage)`
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;

  ${media.lg.max} {
    position: relative;
    width: 100%;
    height: 60vh;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to bottom,
        #000 0%,
        rgba(0, 0, 0, 0.1) 20%,
        rgba(0, 0, 0, 0.1) 60%,
        #000 100%
      );
      z-index: 1;
    }
  }
`

const StyledForm = styled.div`
  position: relative;
  padding: 112px 0;

  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 80%;
    height: 100%;
    background: radial-gradient(
        54.64% 52.88% at 72.76% 50%,
        rgba(0, 0, 0, 0) 44.74%,
        #000000 100%
      ),
      linear-gradient(89.17deg, #000000 40.54%, rgba(0, 0, 0, 0) 74.29%);
    z-index: 1;
  }

  ${media.xl2.max} {
    padding: 170px 0 32px 0;
  }

  ${media.lg.max} {
    padding: 0 0 32px 0;
    &:before {
      display: none;
    }
  }
`

const StyledFormInner = styled.div`
  position: relative;
  max-width: 570px;
  width: 100%;
  z-index: 2;

  ${media.lg.max} {
    margin: -64px 0 0 0;
  }
`

const PageTraders = styled.div`
  margin: 96px 0 0 0;

  ${media.lg.max} {
    margin: 64px 0 0 0;
  }
`

const PageContact: React.FC<PageProps<Queries.PageContactQuery>> = ({
  data,
}) => {
  const PAGE = data?.wpPage?.PageContact!
  const SEO = data?.wpPage?.seo!

  if (!PAGE || !SEO) throw new Error("CMS data didn't load properly")

  const { lg } = useBreakpoint()

  const dataTradersTraders = PAGE?.tradersList!.map((trader) => ({
    title: trader?.tradersListName!,
    email: trader?.tradersListEmail!,
    phone: trader?.tradersListPhone!,
    text: trader?.tradersListRegion!,
    subtitle: trader?.tradersListRole!,
  }))

  const dataTradersCompany = {
    name: PAGE?.tradersCompanyName!,
    nip: PAGE?.tradersCompanyNip!,
    regon: PAGE?.tradersCompanyRegon!,
    krs: PAGE?.tradersCompanyKrs!,
  }

  return (
    <LayoutBasic>
      <Seo
        title={SEO?.title!}
        description={SEO?.metaDesc!}
        ogTitle={SEO?.opengraphTitle!}
        ogDescription={SEO?.opengraphDescription!}
        ogImage={SEO?.opengraphImage?.sourceUrl!}
        twitterTitle={SEO?.twitterTitle}
        twitterDescription={SEO?.twitterDescription}
        twitterImage={SEO?.twitterImage?.sourceUrl}
      />
      <StyledForm>
        <StyledFormBg
          src={
            lg
              ? PAGE?.formBackground?.localFile?.childImageSharp
                  ?.gatsbyImageData!
              : PAGE?.formBackgroundMobile?.localFile?.childImageSharp
                  ?.gatsbyImageData!
          }
          alt=""
        />
        <Container>
          <StyledFormInner>
            <ContactForm title={PAGE?.formTitle!} />
          </StyledFormInner>
        </Container>
      </StyledForm>
      <PageTraders>
        <Traders traders={dataTradersTraders} company={dataTradersCompany} />
      </PageTraders>
    </LayoutBasic>
  )
}

export default PageContact

export const query = graphql`
  query PageContact {
    wpPage(slug: { regex: "/kontakt/" }) {
      PageContact {
        formTitle
        formBackground {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 2000)
            }
          }
        }
        formBackgroundMobile {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 2000)
            }
          }
        }
        tradersList {
          tradersListName
          fieldGroupName
          tradersListEmail
          tradersListPhone
          tradersListRegion
          tradersListRole
        }
        tradersCompanyRegon
        tradersCompanyNip
        tradersCompanyName
        tradersCompanyKrs
      }
      seo {
        title
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`
