import React from 'react'

import {
  Wrapper,
  TradersList,
  Trader,
  TraderInner,
  TraderTitle,
  TraderSubtitle,
  TraderText,
  TraderContact,
  TraderPhone,
  TraderEmail,
  Company,
  CompanyInner,
  CompanyCol,
  CompanyText,
} from 'components/organisms/Traders/Traders.style'
import Container from 'components/atoms/Container'

import generateKey from 'utils/generateKey'

type Props = {
  traders: Array<{
    title: string
    subtitle: string
    text?: string
    phone?: string
    email?: string
  }>
  company: {
    name?: string
    nip?: string
    krs?: string
    regon?: string
  }
}

const Traders: React.FC<Props> = ({ traders, company }) => {
  return (
    <Wrapper>
      <Container>
        {traders && (
          <TradersList>
            {traders.map((trader, traderIndex) => (
              <Trader key={generateKey(traderIndex)}>
                <TraderInner>
                  {trader.title && <TraderTitle>{trader.title}</TraderTitle>}
                  {trader.subtitle && (
                    <TraderSubtitle>{trader.subtitle}</TraderSubtitle>
                  )}
                  {trader.text && <TraderText>{trader.text}</TraderText>}
                  {(trader.phone || trader.email) && (
                    <TraderContact>
                      {trader.phone && (
                        <TraderPhone href={`tel:${trader.phone}`}>
                          {trader.phone}
                        </TraderPhone>
                      )}
                      {trader.email && (
                        <TraderEmail href={`mailto:${trader.email}`}>
                          {trader.email}
                        </TraderEmail>
                      )}
                    </TraderContact>
                  )}
                </TraderInner>
              </Trader>
            ))}
          </TradersList>
        )}
      </Container>
      {company && (
        <Company>
          <Container>
            <CompanyInner>
              <CompanyCol>
                {company.name && <CompanyText>{company.name}</CompanyText>}
                {company.nip && <CompanyText>NIP: {company.nip}</CompanyText>}
              </CompanyCol>
              <CompanyCol>
                {company.krs && <CompanyText>KRS: {company.krs}</CompanyText>}
                {company.regon && (
                  <CompanyText>REGON: {company.regon}</CompanyText>
                )}
              </CompanyCol>
            </CompanyInner>
          </Container>
        </Company>
      )}
    </Wrapper>
  )
}

export default Traders
